(function (window, document) {

    window.addEventListener('cc:mainmenu', async function () {

        function generateRegex(word) {
            const regex = [];

            for (let i = 0; i <= word.length; i++) {
                const prefix = word.slice(0, i);
                const suffix = word.slice(i);
                regex.push(`${prefix}<mark>${suffix}</mark>`);
                if (i > 0) {
                    regex.push(`<mark>${prefix}</mark>${suffix}`);
                }
            }

            regex.push(word);

            return new RegExp(`(${regex.join('|')})`,'gim');
        }

        const regex = generateRegex('TWEET\nSHARE');

        const searchClient = algoliasearch("N4FRU5BTVV", "6b0861bccc3bc8615e6a62b9433e0390"),
            searchboxSelector = '#searchbox',
            searchboxElement = document.querySelector(searchboxSelector),
            hitsSelector = '#hits',
            hitsElement = document.querySelector(hitsSelector);

        if (searchboxElement && hitsElement) {
            const search = instantsearch({
                indexName: "collegeconsensus",
                searchClient,
                searchFunction(helper) {

                    // Ensure we only trigger a search when there's a query
                    if (helper.state.query) {
                        hitsElement.style.display = '';
                        helper.search();
                    } else {
                        hitsElement.style.display = 'none';
                    }
                },
            });

            // noinspection JSUnresolvedReference
            search.addWidgets([
                instantsearch.widgets.searchBox({
                    container: searchboxSelector,
                }),
                instantsearch.widgets.hits({
                    container: hitsSelector,
                    templates: {
                        item(hit, {html, components}) {
                            return html`
                                <a href="${hit.path}">
                                    <div class="hit-title">${components.Highlight({hit, attribute: 'title'})}</div>
                                    <div class="hit-subtitle">${components.Highlight({hit, attribute: 'subtitle'})}
                                    </div>
                                    <div class="hit-content">${components.Snippet({attribute: 'content', hit})}
                                    </div>
                                </a>
                            `;
                        },
                    },
                    transformItems(items) {

                        return items.map(item => {
                            const snippetResult = item._snippetResult;
                            const highlightResult = item._highlightResult;

                            if (snippetResult && snippetResult.content && snippetResult.content.value) {
                                snippetResult.content.value = snippetResult.content.value.replace(regex, '');
                            }

                            if (highlightResult && highlightResult.content && highlightResult.content.value) {
                                highlightResult.content.value = highlightResult.content.value.replace(regex, '');
                            }

                            return item;
                        });
                    },

                }),
            ]);

            search.start();
        }
    });

})(window, document);